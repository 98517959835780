<template>
  <div>
    <h2>Login</h2>

    <form v-if="!$store.state.main.user" @submit.prevent="login">
      <label>
        E-Mail
        <input ref="email" v-model="email" autocomplete="on" type="email" />
      </label>
      <label>
        Passwort
        <input v-model="password" type="password" />
      </label>
      <button
        class="bg-primary-700 hover:bg-primary-800 text-white font-bold py-2 px-4 rounded mt-1"
        type="submit"
      >
        Anmelden
      </button>

      <div class="text-red-500">{{ error }}</div>
    </form>

    <div v-if="$store.state.main.user">
      <button
        class="bg-primary-700 hover:bg-primary-800 text-white font-bold py-2 px-4 rounded mt-1"
        @click="createUser"
      >
        Benutzer erstellen
      </button>
      <button
        class="bg-primary-700 hover:bg-primary-800 text-white font-bold py-2 px-4 rounded mt-1"
        @click="updateUser"
      >
        Benutzer updaten
      </button>
    </div>
  </div>
</template>

<script>
import { supabase } from "@/plugins/supabase";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      error: ""
    };
  },
  mounted() {
    this.$refs.email.focus();
    this.$refs.email.click();
  },
  methods: {
    async createUser() {
      let { user, error } = await supabase.auth.signUp({
        email: "dmohr@gries-schleiftechnik.de",
        password: "Gries2021!",
        data: { admin: true, firstname: "Daniel", lastname: "Mohr" }
      });

      if (error) {
        this.error = error.message;
        console.error(error.message);
      }

      console.log(user);
    },
    async updateUser() {
      const { user, error } = await supabase.auth.update({
        email: "dmohr@gries-schleiftechnik.de",
        // password: "e3q9z4p7",
        data: { admin: true, firstname: "Daniel", lastname: "Mohr" }
      });

      if (error) {
        this.error = error.message;
        console.error(error.message);
      }

      console.log(user);
    },
    async login() {
      let { user, error } = await supabase.auth.signIn({
        email: this.email,
        password: this.password
      });

      if (error) {
        this.error = error.message;
        console.error(error.message);
      }

      console.log(user);

      // set the user if loggedin
      this.$store.commit("setUser", supabase.auth.user());
      this.$router.push("/");
    }
  }
};
</script>

<style scoped>
@media screen and (min-width: 40em) {
  input {
    max-width: 300px;
  }
}
</style>
